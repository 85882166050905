(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-search/assets/javascripts/search.js') >= 0) return;  svs.modules.push('/components/components/analytics-search/assets/javascripts/search.js');

var svs = svs || {};

(function(svs) {
  svs.components = svs.components || {};
  svs.components.analytics = svs.components.analytics || {};
  svs.core = svs.core || {};
  svs.core.analytics = svs.core.analytics || {};
  svs.core.analytics.adl = svs.core.analytics.adl || {};

  svs.components.analytics.trackSearch = function(query, category) {
    var encodedQuery = encodeURIComponent(query);
    var encodedCategory = encodeURIComponent(category);
    var url = location.pathname;
    var logEvent = new svs.core.log.Message(svs.core.log.MessageType.event);

    logEvent.eventCategory = 'search';
    logEvent.eventAction = category;
    logEvent.eventLabel = query;
    logEvent.eventValue = 1;
    svs.core.log.track('svs.components.analytics', logEvent);

    window.adobeDataLayer.adlPush('search', {
      site: svs.core.analytics.adl.site,
      consent: svs.core.analytics.adl.consent,
      user: svs.core.analytics.adl.user,
      page: svs.core.analytics.adl.page,
      category: category,
      action: query,
      label: '',
      value: ''
    });

    if (url.charAt(url.length - 1) === '/') {
      url = url.slice(0, -1);
    }
    if (location.search) {
      url = url + location.search + '&';
    } else {
      url = url + '?';
    }
    url = url + 'q=' + encodedQuery + '&qc=' + encodedCategory;
    dataLayerPush({
      event: 'pageview',
      page: {
        pageInfo: {
          pageID: document.location.pathname,
          pageName: document.title,
          sysEnv: svs.core.analytics.data.svsanalytics.formFactor,
          destinationURL: url
        }
      }
    });
  };
})(svs);


 })(window);